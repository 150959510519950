
@import '~@angular/material/theming';
@include mat-core();

$super-vade-mecum: (50: #e2f0f7, 100: #b6daea, 200: #85c1dd, 300: #54a8cf, 400: #2f96c4, 500: #0a83ba, 600: #097bb3, 700: #0770ab, 800: #0566a3, 900: #035394, A100: #bfdfff, A200: #8cc5ff, A400: #59acff, A700: #409fff, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000, ));
$super-vade-mecum-primary: mat-palette($super-vade-mecum);
$super-vade-mecum-accent: mat-palette($mat-blue);
$super-vade-mecum-warn: mat-palette($mat-red);
$super-vade-mecum-theme: mat-light-theme($super-vade-mecum-primary, $super-vade-mecum-accent, $super-vade-mecum-warn);

@include angular-material-theme($super-vade-mecum-theme);

@import "./flexbox.scss";
@import "./palette.scss";

* {
  font-family: Arial, Helvetica, sans-serif;
}

.fonte-leitura {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.invert-y {
  transform: scaleY(-1);
}

.invert-x {
  transform: scaleX(-1);
}

.shadow {
  box-shadow: 0px 0px 2px 1px rgba($color: black, $alpha: 0.3);
}

.clickable {
  cursor: pointer;
  opacity: 0.9;
  transition: opacity ease-in-out 200ms;
}

.clickable:hover {
  opacity: 1;
}

p {
  margin: 0;
}

a {
  cursor: pointer;
}

.noselect,
.noselect * {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}