@charset "UTF-8";

@font-face {
  font-family: "svm-ui";
  src:url("fonts/svm-ui.eot");
  src:url("fonts/svm-ui.eot?#iefix") format("embedded-opentype"),
    url("fonts/svm-ui.woff") format("woff"),
    url("fonts/svm-ui.ttf") format("truetype"),
    url("fonts/svm-ui.svg#svm-ui") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;

}

[data-icon]:before {
  font-family: "svm-ui" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "svm-ui" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-prova:before {
  content: "\61";
}
.icon-play:before {
  content: "\62";
}
.icon-pause:before {
  content: "\63";
}
.icon-comentario:before {
  content: "\64";
}
.icon-busca:before {
  content: "\65";
}
.icon-borracha:before {
  content: "\66";
}
.icon-down:before {
  content: "\67";
}
.icon-left:before {
  content: "\68";
}
.icon-right:before {
  content: "\69";
}
.icon-up:before {
  content: "\6a";
}
.icon-versao:before {
  content: "\6b";
}
.icon-check:before {
  content: "\6c";
}
.icon-double-check:before {
  content: "\6d";
}
.icon-favoritos-contorno:before {
  content: "\6e";
}
.icon-favoritos-preenchido:before {
  content: "\6f";
}
.icon-close:before {
  content: "\70";
}
.icon-sort:before {
  content: "\71";
}
.icon-menu:before {
  content: "\72";
}
.icon-new:before {
  content: "\73";
}
.icon-marcador:before {
  content: "\74";
}
.icon-color-picker:before {
  content: "\75";
}
.icon-logo:before {
  content: "\76";
}
.icon-info:before {
  content: "\77";
}
.icon-sync:before {
  content: "\78";
}
.icon-sync-ok:before {
  content: "\79";
}
.icon-sync-offline:before {
  content: "\7a";
}
.icon-cursor-curvo:before {
  content: "\41";
}
.icon-cursor-quadrado:before {
  content: "\42";
}
.icon-mnemonico:before {
  content: "\43";
}
.icon-cursor-ponteiro:before {
  content: "\44";
}
.icon-underline:before {
  content: "\45";
}
