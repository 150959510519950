#updater{
    display: none;
    position: fixed;
    background-color: #0a83ba;
    color: white;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
}

#updater .wrapper{
    position: relative; 
    top: 50%;
    transform: translateY(-50%);
}

#updater #update-progressbar{
    margin: auto;
    width: 480px;
    height: 4px;
    background-color: #53a8ce;
    border-radius: 4px;
}

#updater #update-progressbar #update-progressbar-step{
    height: 100%;
    background-color: #064e6f;
    border-radius: 4px;
}

#updater #update-msg{
    margin-top: 8px;
    text-align: center;
    font-size: 14pt;
}

#app-logo{
    text-align: center;
    margin-bottom: 16px;
}