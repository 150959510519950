@import "./colors.scss";

.background-primary{
    background-color: $background-primary;
    color: $foreground-primary;
}

.foreground-primary{
    background-color: $foreground-primary;
    color: $background-primary;
}

.background-accent{
    background-color: $background-accent;
    color: $foreground-accent;
}

.foreground-accent{
    background-color: $foreground-accent;
    color: $background-accent;
}