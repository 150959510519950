.container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.start-start {
    align-items: flex-start;
    justify-content: flex-start;
}

.start-center {
    align-items: flex-start;
    justify-content: center;
}

.start-end {
    align-items: flex-start;
    justify-content: flex-end;
}

.start-stretch {
    align-items: flex-start;
    justify-content: stretch;
}

.center-start {
    align-items: center;
    justify-content: flex-start;
}

.center-center {
    align-items: center;
    justify-content: center;
}

.center-end {
    align-items: center;
    justify-content: flex-end;
}

.center-stretch {
    align-items: center;
    justify-content: stretch;
}

.center-space-between {
    align-items: center;
    justify-content: space-between;
}

.center-space-evenly {
    align-items: center;
    justify-content: space-evenly;
}

.end-start {
    align-items: flex-end;
    justify-content: flex-start;
}

.end-center {
    align-items: flex-end;
    justify-content: center;
}

.end-end {
    align-items: flex-end;
    justify-content: flex-end;
}

.end-stretch {
    align-items: flex-end;
    justify-content: stretch;
}

.stretch-start {
    align-items: stretch;
    justify-content: flex-start;
}

.stretch-center {
    align-items: stretch;
    justify-content: center;
}

.stretch-end {
    align-items: stretch;
    justify-content: flex-end;
}

.stretch-stretch {
    align-items: stretch;
    justify-content: stretch;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.hide-overflow {
    overflow: hidden;
}

@for $i from 1 through 10 {
    .flex-#{$i} {
        flex: $i;
    }
}

.wrap {
    flex-wrap: wrap;
}